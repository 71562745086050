import * as React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { isMobileState } from '@/recoil/layout'
import { useRecoilValue } from 'recoil'

import Layout from '@/layouts/Default'
import ProductsGridItem from '@/components/ProductsGridItem'

import {
  Container,
  Introduction,
  Subtitle,
  Title,
  Text,
  ProductsGrid
} from './other-products.style'

const OtherProductsPage = ({
  data,
  pageContext: {
    pagePath,
    marketCode: pageMarketCode
  }
}) => {
  if (!data) return null
  const products = data.drupal.otherProductsQuery?.entities
  const isMobile = useRecoilValue(isMobileState)

  return (
    <Layout path={ pagePath } plainHeader hrefLangsLocales={ ['en-nz'] } pageMarketCode={ pageMarketCode }>
      <Helmet>
        <title>Cloudy Bay | Wines | Merchandise | New Zealand</title>
        <meta
          name='description'
          content='Explore Cloudy Bay’s other products, including limited edition wines from Marlborough and Central Otago – New Zealand'
        />
      </Helmet>
      <Container>
        <Introduction>
          <Subtitle>Our products</Subtitle>
          <Title>Discover our products</Title>
          <Text>
            Bring a piece of Cloudy Bay home.<br />
            Explore our curated range of other products, including special wines, tableware and great gift ideas.
          </Text>
        </Introduction>
        <ProductsGrid>
          {products.filter(p => p.entityPublished).map((product, i) => {
            return (
              <ProductsGridItem
                key={ `other-product-${i}` }
                title={ product.fieldWineProductName }
                price={ product.fieldWineProductPrice }
                packshot={ product.fieldWineProductPackshot }
                url={ product.path?.alias }
              />
            )
          })}
        </ProductsGrid>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query OtherProductsQuery{
    drupal {
      otherProductsQuery: nodeQuery(
        filter: {conditions: [{field: "type", value: "other_products", operator: EQUAL}]}
        limit: 1000
      ) {
        entities {
          ... on Drupal_NodeOtherProducts {
            path {
              alias
            }
            fieldWineProductName
            fieldWineProductPrice
            entityPublished
            fieldWineProductPackshot {
              url
              alt
            }
          }
        }
      }
    }
  }
`

export default OtherProductsPage
